var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-product-sidebar","visible":_vm.isAddNewProductSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-product-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.actionType == 0 ? " Add New Product" : _vm.actionType == 1 ? " Update Product" : "Delete Product")+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Product Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Product Name","label-for":"product-name"}},[_c('b-form-input',{attrs:{"id":"product-name","disabled":_vm.actionType==2,"autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Product Name"},model:{value:(_vm.currentProduct.name),callback:function ($$v) {_vm.$set(_vm.currentProduct, "name", $$v)},expression:"currentProduct.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Product Code","label-for":"product-code"}},[_c('b-form-input',{attrs:{"id":"product-code","disabled":_vm.actionType==2,"autofocus":"","trim":"","placeholder":"Product Code"},model:{value:(_vm.currentProduct.productCode),callback:function ($$v) {_vm.$set(_vm.currentProduct, "productCode", $$v)},expression:"currentProduct.productCode"}})],1),_c('b-form-group',{attrs:{"label":"Mould"}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"mould","multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"code","options":_vm.moulds,"disabled":_vm.actionType==2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var code = ref.code;
return [_c('span',[_vm._v(" "+_vm._s(code))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.currentMould),callback:function ($$v) {_vm.currentMould=$$v},expression:"currentMould"}})],1),_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"disabled":_vm.actionType==2,"id":"input-isActive"},model:{value:(_vm.currentIsActive),callback:function ($$v) {_vm.currentIsActive=$$v},expression:"currentIsActive"}},[_vm._v(" IsActive ")]),_c('div',{staticClass:"d-flex mt-2"},[(_vm.actionType != 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.currentProduct==null|| _vm.currentProduct.id==null? 'Add':'Update')+" ")]):_vm._e(),(_vm.currentProduct!=null&& _vm.currentProduct.id!=null && _vm.actionType == 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.deleteProduct}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }