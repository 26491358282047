import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductList() {
  // Use toast
  const toast = useToast()
  const refProductListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'productCode', sortable: true },
    { key: 'mould', label :'Mould Code' },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const search = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refProductListTable.value.refresh()
    GetProducts()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetProducts() {
    store.dispatch('pamis-product/fetchProducts')
      .then(response => {
        const productResult = response.data;

        if(productResult.length > 0){       
          productResult.forEach(element => {
            if (element.moulds.length > 0) {
              var moulds= element.moulds.map(x => x.code);
              var mouldList = '';

              if (moulds.length < 3) {
                mouldList = moulds.join(', ');
              } else {
                for (let index = 0; index < 3; index++) {
                  
                  if (index != 2) {
                    mouldList += moulds[index] + ', ';
                  } else {
                    mouldList += moulds[index] + ' ...';
                  }
                }
              }
              
              element.mould = mouldList;
            }
          });
        }

        row.value = productResult;
        totalProducts.value = productResult.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Product list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  GetProducts()

  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalProducts,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refProductListTable,
    refetchData
  }
}
