import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/workorder/Product/Products')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchAllMould(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/workorder/mould/moulds')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, ProductFormData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/workorder/Product/Create', ProductFormData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/workorder/Product/Update?id=${params.product.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/workorder/Product/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
