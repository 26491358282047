<template>
  <b-sidebar
    id="add-new-product-sidebar"
    :visible="isAddNewProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Product" : actionType == 1 ? " Update Product" : "Delete Product"}}
         
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Product Name"
            rules="required"
          >
            <b-form-group
              label="Product Name"
              label-for="product-name"
            >
              <b-form-input
                id="product-name"
                v-model="currentProduct.name"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Product Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>

              <b-form-group
              label="Product Code"
              label-for="product-code"
            >
              <b-form-input
                id="product-code"
                v-model="currentProduct.productCode"
                :disabled="actionType==2"
                autofocus
                trim
                placeholder="Product Code"
              />
              </b-form-group>

            <b-form-group label="Mould">
              <v-select
                  id="mould"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="currentMould"
                  label="code"
                  :options="moulds"
                  :disabled="actionType==2"
                  class="select-size-lg">
                    <template #option="{ id, code }">
                        <span> {{ code }}</span><br />
                        <small class="text-muted"> {{ id }} </small>
                    </template>
              </v-select>
            </b-form-group>

              <b-form-checkbox 
                class="mt-2"
                :disabled="actionType==2"
                id="input-isActive"
                v-model="currentIsActive"
              >
                IsActive
              </b-form-checkbox>
     
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentProduct==null|| currentProduct.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentProduct!=null&& currentProduct.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteProduct"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mould from '@/router/mould'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.getMoulds();
  },
   methods:{
    getMoulds() {
      this.$store.dispatch('pamis-product/fetchAllMould')
        .then((result) => {
          if (result != null) {
            this.moulds = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Mould By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    deleteProduct(){
       this.$store.dispatch('pamis-product/deleteProduct', this.currentProduct.id)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-product-sidebar-active', false)
          this.notification()
        });
    },

    submitForm(){
      
      var process = "pamis-product/addProduct";

      if(this.currentProduct !=null &&  this.currentProduct.id != null){
        process = "pamis-product/updateProduct";
      } 

      const data = {};
      this.currentProduct.isActive = this.currentIsActive;
      this.currentProduct.mouldIds = this.currentMould.map(x => x.id);
      data.product = this.currentProduct;
       
      this.$store.dispatch(process, data)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-product-sidebar-active', false)
          this.notification()
        });

    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
  
  watch: {
     productdata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined || n.selectedProduct.id==null)
          {
            this.currentProduct={
              Id:null,
              name:null,
              productCode:null,
              isActive:null,
            }
           this.currentMould = null;
          } else
          { 
            var product = n.selectedProduct;
            this.currentProduct = product;
            this.currentIsActive = product.isActive;

            this.currentMould= product.moulds;
          }
        },
        deep: true
    }
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductSidebarActive',
    event: 'update:is-add-new-product-sidebar-active',
  },
  props: {
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentIsActive : true,
      moulds : [],
      currentMould : null,
      currentProduct:{
        Id:null,
        name:null,
        productCode:null,
        isActive:null,
      },
    }
  },
   
  inject: ['productdata'],

  setup(props,  { emit }) {
    const blankProductData = {
      id:null,
      name:null,
      productCode:null,
      isActive:null,
    }

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetProductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
      emit('refetch-data')
      emit('update:is-add-new-product-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-product/addProduct', currentProduct)
        .then(() => {
        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductData)

    return {
      productData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-product-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
